.analyzeHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 0.12vw solid #cc33ff;
}

.analyzeVarieties {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 20%;
}

.anVar{
    padding: 1vw;
    font-family: "Axiforma", sans-serif;
    font-weight: 300;
    font-size: 0.9375rem;
    color: #cc33ff;
    border: 0.1vw solid #cc33ff;
    border-radius: 1.5vw;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vw;
    height: 2.5vw;
    width: 5.64vw;
    margin: 0 0.2vw;
}

.anVar:hover {
    color: white;
}

.analyzeVarieties .active{
    background-color: #cc33ff!important;
    color: #100026!important;
}

.analyzeHeader h3{
    font-family: 'Axiforma', sans-serif;
    font-weight: 500;
    font-size: 1.875rem;
    color: #cc33ff;
    padding: 1.719vw 0;
}

.analyzeRezults {
    display: flex;
    flex-direction: row;
    padding-bottom: 2vw;
}

.analyzeRezults > img{
    margin-right: 2vw;
    width: 13.542vw;
    height: 7.552vw;
    border: 0.1vw solid rgb(204, 51, 255);
    object-fit: cover;
}

.analyzeContent{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.analyzeIcons{
    display: flex;
    align-self: flex-start;
}

.analyzeIcons img{
    width: 1.771vw; 
    height: 1.771vw;
    margin: 0 0.2vw;
    cursor: pointer
}

.analyzeInfo{
    margin: 0 0.5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Axiforma', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #cc33ff;
}

.analyzeInfo div{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.analyzeInfo img{
    width: 1.2vw; 
    height: 1.2vw;
    margin: 0 0.3vw;
    cursor: pointer
}


.analyzeMembers{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.analyzeMembers div{
    /* margin: 0 0.5vw; */
    cursor: pointer;  
    font-family: 'Axiforma', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #cc33ff;  
}
