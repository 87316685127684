.playlists {
    color: #fff;
    font-family: "Axiforma";
    font-size: 1.3vw;
    font-style: normal;
    margin-bottom: 2vw;
    border-top: 0.12vw solid #cc33ff;
}

.playlists h3{
    color: #cc33ff;
    font-family: 'Axiforma', sans-serif;
    font-size: 1.875rem;
    font-weight: 500;
}

#no-playlists{
    color: #cc33ff;
    font-family: 'Axiforma', sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    font-style: italic;
}
