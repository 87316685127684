.login-form{
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding-top: 1vw;
  padding-bottom: 2.29vw;
}

.login-input-wrapper {
  height: 3.07vw;
  width: 25vw ;
  margin-bottom: 1.46vw;
}

.login-Input {
  height: 3.07vw;
  width: 25vw;
  background: #ae53fa;
  border: none;
  text-align: center;
  color: white;
  font-family: "Axiforma", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.login-Input:focus{
  outline: none;
  color: white;
  font-family: "Axiforma", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.login-input-wrapper ::placeholder{
  color: white;
  font-family: "Axiforma", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.login-Input:focus::placeholder {
  color: transparent;
}

.continue-google {
  height: 2.3vw;
  width: 25vw;
  color: #9d17fb;
  text-align: center;
  border: 0.05vw solid #9d17fb;
  font-family: "Axiforma", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 2vw;
  padding: 0.3vw;
  margin-bottom: 1.46vw;
  cursor: pointer;
}

.login-button{
  width: 7.5vw;
  height: 2.3vw;
  background: #cc33ff;
  outline: none;
  border-radius: 2vw;
  border: none;
  color: white;
  font-family: "Axiforma", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1.46vw;
}

.move-to-register{
  width: 25vw;
  color: #ab44fb;
  font-family: "Axiforma", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0.8vw;
  cursor: pointer;
}

.move-to-register span{
  color: #cc33ff
}

.message {
  color:#9d17fb;
  margin-bottom: 0.5vw;
  text-align: center;
}

.modal-close{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1vw 2vw;
}

.modal-close img{
  cursor: pointer;
}
