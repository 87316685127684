.player-wrapper {
    position: relative;
    margin-top: 4.5vh;
    margin-bottom: 0.5vh;
    display: flex;
    flex-direction: row;
    height: 35vw;
}

.player-wrapper .player-video {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  height: 35vw;
}

.player-wrapper .withComments {
  width: 75%;
  margin-right: 1%;
}

.react-player {
    position: relative;
    border: .1vw solid #cc33ff;
    top: 0;
    left: 0;
}


/* .react-player */
