.search-result-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.single-result-wrapper {
    width: 9.5vw;
    margin-bottom: 0.6vw;
}

.single-result-wrapper:not(:nth-child(6n+6)) {
    margin-right: 0.6vw;
}
