.single-video{
  background: #441478;
  border-radius: 2vw;
}

.addVideo-form{
  background: transparent;
  width: 100%;
  margin: 2rem auto;
  border-top: 0.03vw solid #c3f;
  padding-top: 1.5vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 2vw;
}

.editVideo{
  border: none;
  padding-left: 2vw;
  margin-top: 0;
}

.edit-link{   
  font-family: "Axiforma", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  text-decoration: underline;
  padding-left: 2vw;
}

.addVideo-component{
  width: 95%;
}

.addVideo-form label{
  display: block;
  padding: 1rem 0 .5rem;
  color: #c3f;
  font-weight: 400;
  font-size: 1.125rem;
  font-family: "Axiforma", sans-serif;
}

.input-wrapper{
  border-radius: 5vw;
  width: 100%;
  height: 2.25vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  background-color: #fff;
}

.addVideo-form input{
  display: block;
  border: transparent;
  width: 100%;
  border-radius: 3vw;
  height: 2.25vw;
  padding: 0.7rem;
  font-size: 1.125rem;
  font-family: "Axiforma", sans-serif;
  font-weight: 400;
  color: #c3f;
}

.addVideoBtn {
  border: 0;
  background: #c3f;
  color: #100026;
  cursor: pointer;
  border-radius: 2vw;
  font-family: "Axiforma", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  width: 7vw;
  height: 2.25vw; 
  padding: 0.5vw;
}

.addVideo-form input:focus{
  outline: none;
}

.input-wrapper img{
  width: 1.458vw;
  height: 1.458vw;
  margin-right: 0.5vw;
}


.addVideoBtn:hover{
  background: #a74bd1;
}

.addVideoDropdown{
  width: 100%;
  user-select: none;
  border: transparent;
}

.addVideoDropdown .addVideoDropdownBtn{
  width: 95%;
  border: transparent;
  border-radius: 3vw;
  height: 2.5vw;
  padding: 0.7rem 1rem;
  font-size: 1.125rem;
  font-family: "Axiforma", sans-serif;
  font-weight: 400;
  color: #c3f;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2vw;
  cursor: pointer;
}

.addVideoDropdown .addVideoDropdown-content {
  position: absolute;
  padding: 0.5vw;
  margin: 0.5vw;
  background: #fff;
  width: 25%;
  border-radius: 1vw;
  z-index: 1000;
}

.addVideoDropdown .addVideoDropdown-content .addVideoDropdown-item {
  padding: 0.2vw 1vw;
  cursor: pointer;
  transition: all 0.2s;
  font-family: "Axiforma", sans-serif;
  font-weight: 400;
  color: #c3f;
}

.addVideoDropdown .addVideoDropdown-content .addVideoDropdown-item:hover {
  background: #f4f4f4;
}

.label-members{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1rem 0 .5rem;;
}

.member {
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  height: auto;
  margin-left:0.5vw;
}

.member img{
  width: 1.094vw;
  height: 1.094vw;
}

.member-name{
  margin-right: 0.3vw;
  font-weight: 300;
  font-size: 1.125rem;
  font-family: "Axiforma", sans-serif;
}

.members-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0 .5rem;
  font-size: 1.125rem;
  font-family: "Axiforma", sans-serif;
  font-weight: 400;
  color: #c3f;
}


.members{
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 70vh;
    padding: 0.5vw;
}

.members::-webkit-scrollbar {
  width: 0.3vw;
}

.members::-webkit-scrollbar-track {
  border-radius: 2vw;
  box-shadow: inset 0 0 0.5vw rgba(0, 0, 0, 0.25);
}

.members::-webkit-scrollbar-thumb {
  border-radius: 2vw;
  background-color: #cc33ff;
}

.close-icon{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.close-icon img{
  width: 1.05vw;
  height: 1.05vw;
  margin: 1vw;
}

.members .membersHeader{
  padding: 0.5vw 1vw;
  font-weight: 500;
}


.modal-button{
  border: 0;
  background: #c3f;
  color: #100026;
  cursor: pointer;
  border-radius: 2vw;
  font-family: "Axiforma", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  padding: 0.2vw 0.5vw;
  margin: 0.2vw 0;
}

.modal-member{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .member-choose{

}

.dataResult {
  margin-top: 5px;
  width: 300px;
  height: auto;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
  display: none;
}


.dataResult .dataItem {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  color: black;
} */
