
.side {
  position: relative;
  width: 25%;
  height: 33vw;
  right: 0;
  top: 0;
  overflow: hidden;
  padding-left: 1%;
}

.side .activities {
  border-radius: 0.8vw;
  overflow: hidden;
  height: 100%;
  max-height: 35vw;
}

.activities__header{
  border-radius: 3.5vw;
  background: #cc33ff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.2rem;
  color: #3a0f68;
  height: 6%;
  margin-bottom: 1vw;
}

.activities__header p{
  font-family: 'Axiforma', sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  height: 1.5vw;
}

.activities__header img{
  height: 0.8vw;
  cursor: pointer;
}

.activities h4{
  font-size: 1.1vw;
  font-family: 'Axiforma', sans-serif;
  font-weight: 300;
  text-align: center;
}

.comments-content {
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
  height: 72%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.comment-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.2rem;
  width: auto;
  border-radius: 1vw;
  font-size: 0.8rem;
}

.comment-wrapper > img{
  width: 15%;
}
.comments-content::-webkit-scrollbar {
  width: 0.3vw;
}

.comments-content::-webkit-scrollbar-track {
  border-radius: 2vw;
  box-shadow: inset 0 0 0.5vw rgba(0, 0, 0, 0.25);
}

.comments-content::-webkit-scrollbar-thumb {
  border-radius: 2vw;
  background-color: #cc33ff;
}

.comment-wrapper h6{
  color: #cc33ff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.875rem;
  font-family: "Axiforma", sans-serif;
  font-weight: 500;
}

.comment {
  font-size: 0.9vw;
  padding-left: 0.7vw;
  width: 85%;
}


.editIcon{
  text-align: right;
  cursor: pointer;
  height: 1vw;
}

.comment-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  overflow-wrap: break-word;
  font-size: 0.875rem;
  font-family: "Axiforma", sans-serif;
  font-weight: 300;
}

.comment-icons{
  display: flex;
  flex-direction: row;
  width: 25%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5vw;
}

.comment-icons img{
  width: 1.09vw;
  height: 1.09vw;
}

#send-image {
  position: relative;
  padding: 0.45vw;
  display: flex;
  justify-content: flex-end;
}

.addComment-form{
  width: auto;
  padding-top: 0.5vw;
}

.addComment-content{
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 5.2vw;
}


.textarea{
  position: relative;
  border-style: hidden;
  width: 100%;
  height: 3vw;
  max-height: 15vw;
  background-color: inherit;
  border-bottom: 0.1vw solid #cc33ff;
  font-size: 1rem;
  outline: none;
  color: white;
  resize: vertical;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 0.875rem;
  font-family: "Axiforma", sans-serif;
  font-weight: 300;
  cursor: auto;
}

.addComment-content ::placeholder {
  color: #cc33ff;
  font-size: 0.875rem;
  font-family: "Axiforma", sans-serif;
  font-weight: 300;
  text-align: justify;
  position: absolute;
  text-align: left;
  width: 100%;
  bottom: 0.2vw;
}

.textarea:focus::placeholder {
  color: transparent;
}

.textarea::-webkit-scrollbar {
  width: 0.3vw;
  cursor: pointer;
}

.textarea::-webkit-scrollbar-track {
  border-radius: 0.3vw;
  box-shadow: inset 0 0 0.1vw rgba(0, 0, 0, 0.25);
}

.textarea::-webkit-scrollbar-thumb {
  border-radius: 0.3vw;
  background-color: #cc33ff;
}


.editComment-buttons{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.5vw;
}

.editComment-cancel{
  color: #cc33ff;
  font-size: 0.8125rem;
  font-family: "Axiforma", sans-serif;
  font-weight: 300;
  padding-right: 0.5vw;
  border-right: 0.1vw solid #cc33ff;
  height: 1rem;
  cursor: pointer;
}

.editComment-save{
  color: #cc33ff;
  font-size: 0.8125rem;
  font-family: "Axiforma", sans-serif;
  font-weight: 300;
  border-bottom: 0.1vw solid #cc33ff;
  margin-left: 0.5vw;
  height: 1rem;
  cursor: pointer;
}
