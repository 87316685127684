.header {
    font-family: 'Axiforma', sans-serif;
    font-weight: lighter;
    color: white;
    padding-top: 3vh;
    display: flex;
    align-items: center;
  }

  .header-content {
    width: 59.8vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 19.5vw;
    justify-content: space-between;
  }

  .header #site-logo {
    width: 20vw;
  }

  .search-relation {
    position: relative;
  }

  .search-wrapper {
    border-radius: 5vw;
    border: 0.05vw solid #cc33ff;
    height: 2.5vw;
    width: 30vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }


  .search-wrapper input{
    font-family: 'Axiforma', sans-serif;
    font-size: 1.5rem;
    font-weight: lighter;
    color: white;
  }

  .search-wrapper ::placeholder {
    color: #cc33ff;
    font-size: 1rem;
  }

  .header .site-search {
    background-color: inherit;
    border: none;
    display: block;
    font-size: 1vw;
    padding: 1.4vh 1vw 1vh 1vw;
    width: 75%;
    height: 100%;
    line-height: 1vw;
}

  .header .site-search:focus {
    outline: none;
  }

  .header .site-search:focus::placeholder {
    color: transparent;
  }

  #search-button, #search-icon {
    display: flex;
    justify-content: center;
    overflow: hidden;
    height:100%;
}


#search-button {
  background-color: inherit;
  align-items: center;
  cursor: pointer;
  width: 9.8%;
  height: 100%;
}

#search-icon {
  height: 100%;
  width: 90%;
}


#search-icon-img {
  height: 100%;
  width: 100%;
  padding-right: 0.1vw;
}

#filter-button {
    display: flex;
    align-items: center;
    overflow: hidden;
    color: #c3f;
    font-size: 1em;
    justify-content: center;
    cursor: pointer;
    height: 90%;
    width: 45%;
}

#filter-img-and-text {
  width: 80%;
  height: 80%;
  font-size: 1vw;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-items: center;
}

#filter-img-and-text span{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.2vw;
  font-family: "Axiforma", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

#filter-icon-img {
  height: 85%;
  padding-right: 5%;
}

  .filter-list {
    padding: 0.7vw;
    background-color: #fff;
    color: #7028b0;
    font-weight: 400;
    position: absolute;
    width: 9.2vw;
    top: 3vw;
    left: 68%;
    z-index: 5;
}

.filter-list .filter-by {
    cursor: pointer;
    padding: 0.5vw;
    width: 100%;
    font-family: "Axiforma", sans-serif;
    font-weight: 400;
    font-size: 1rem;
}

.filter-list .filter-genre {
    cursor: pointer;
    padding: 0.5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.filter-list .filter-genre img {
  height: auto;
}

.genre-list{
  padding: 0.7vw;
  background-color: #fff;
  color: #7028b0;
  font-weight: 400;
  position: absolute;
  width: 9vw;
  top: 8vw;
  left: 102%;
  z-index: 5;
}

.genre-list .filter-by-genre{
  cursor: pointer;
  padding: 0.5vw;
  width: 100%;
  font-family: "Axiforma", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

.filter-list .filter-by:hover{
  background: #d589ef;
}

.genre-list .filter-by-genre:hover{
  background: #d589ef;
}

.addSearchDropdown{
  width: 90%;
  position: relative;
  user-select: none;
}

.addSearchDropdown .addSearchDropdown-content {
  position: absolute;
  top: 110%;
  padding: 0.75vw;
  background: #fff;
  box-shadow: 0.1vw 0.1vw 0.5vw 0.3vw rgba(0,0,0,0.06);
  font-weight: 400;
  color: #333;
  width: 100%;
  border-radius: 1vw;
  z-index: 1000;
}

.addSearchDropdown .addSearchDropdown-content .addSearchDropdown-item {
  padding: 0.55vw;
  cursor: pointer;
  transition: all 0.2s;
}

.addSearchDropdown .addSearchDropdown-content .addSearchDropdown-item:hover {
  background: #f4f4f4;
}


.header-username {
  width: 100%;
  text-align: left;
  color:white !important;
  font-size: 1rem;
}

#login-img {
  width: 2.3vw;
  cursor: pointer;
}

.header-profile {
  width: 16vw;
  padding-left: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
}


.profile-name {
  text-align: left;
  background: inherit;
  color: #cc33ff;
  font-size: 1.2rem;
  cursor: pointer;
  line-height: 1vw;
  outline: none;
  padding: 3%;
}

.profile-menu{
  padding: 0.7vw;
  background-color: #fff;
  color: #7028b0;
  font-weight: 400;
  position: absolute;
  margin-top: 1vw;
  width: 9vw;
  top: 3vw;
  left: 83%;
  z-index: 5;
}

.profile-menu .profile-menu-object {
  cursor: pointer;
  padding: 0.5vw;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
}

.profile-menu .profile-menu-object:hover{
  background: #d589ef;
}
