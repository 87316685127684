.addPlaylist {
    width: 15%;
    position: absolute;
    background-color: azure;
    z-index: 1;
  }
  
  .create-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.8vw;
  }

  .create-button > button {
    font-family: 'Axiforma', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    width: 10vw;
    height: 2.3vw;
    border-radius: 2vw;
    border-style: hidden;
    background: #cc33ff;
    color: white;
  }

  
  .plName-input{
    width: 15vw;
    height: 4vw;
    padding: 0.5vw;
  }
  
  .plName-input > input{
    position: absolute;
    width: 14vw;
    height: 3vw;
    font-family: "Axiforma", sans-serif;
    font-size: 1rem;
    color: #7734b3;
    font-weight: 400;
    background: #deb7eb;
    border: 0.1vw solid #ac8ec9;
    padding: 3%;
  }
  
  .plName-input ::placeholder {
    padding-left: 5%;
    font-family: "Axiforma", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: #c69bdd
  }
  
  .plName-input > input:focus{
    pointer-events: none;
    outline: 0.1vw solid #7734b3;
  }