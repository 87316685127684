.slide-wrapper-for-four:not(:last-child) {
    margin-right: 0.8vw;
}
.slide-wrapper-for-six:not(:last-child) {
    margin-right: 0.6vw;
}

.videoInfo {
  width: 100%;
}

.videoInfo h6{
  font: 'Axiforma', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  max-height: 1.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
