.genres {
    color: #b456fb;
    font-style: normal;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vw;
    height: 2.5vw;
}

.genre {
    padding: 1vw;
    font-family: "Axiforma", sans-serif;
    font-weight: 300;
    font-size: 0.9375rem;
    color: #cc33ff;
    border: 0.1vw solid #cc33ff;
    border-radius: 1.5vw;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vw;
    width: 10%;
    margin: 0 0.2vw;
    text-transform: capitalize;
}

.genre:hover {
    color: white;
}

.genres .active {
    background-color: #cc33ff!important;
    color: #100026!important;
}
