.app-first-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-first-content {
  width: 60vw;
}

.app-second-section{
  background: linear-gradient(#470061,#1e002e);
  padding-top: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-second-content {
  width: 60vw;
}
