.allVideos-wrapper {
    color: #fff;
    font-family: "Axiforma";
    font-size: 1rem;
    font-style: 300;
    margin-top: 2.5vw;
    margin-bottom: 2.5vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    min-height: 100vh;
}

.all-playlist-videos {
    width: 60vw;
    border-top: 0.1vw solid #cc33ff;
    padding: 1.5vw 0;
}

.all-playlist-videos-content {
    width: 100%;
    /* height: 100vh; */
    max-height: 80vh;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: hidden;
}


.all-playlist-videos-content::-webkit-scrollbar {
    width: 0.5vw;
  }
  
.all-playlist-videos-content::-webkit-scrollbar-track {
    border-radius: 2vw;
    box-shadow: inset 0 0 0.5vw rgba(0, 0, 0, 0.25);
  }
  
.all-playlist-videos-content::-webkit-scrollbar-thumb {
    border-radius: 2vw;
    background-color: #cc33ff;
  }


.single-video-wrapper {
    position: relative;
    display: flex;
    width: 50%;
    padding-right: 0.8vw;
    margin-bottom: 1.6vw;
}

.single-video-bg {
    height: 5.4vw;
    min-width: 9.5vw;
    margin-right: 0.8vw;
    display: flex;
}


.single-video-wrapper h4 {
    font-size: 1.2vw;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.show-all-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5vw;
}

.show-all-heading h6{
    color: #c3f;
    font-family: 'Axiforma', sans-serif;
    font-size: 1.875rem;
    font-style: 400;
}

.sah-icons-wrapper{
    width: 18%;
    padding: 0 0.2vw;
    display: flex;
    justify-content: flex-end;
}

.sah-icons-wrapper .header-icon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    height: 1.8vw;
}

.header-icon-wrapper img{
    height: 1.15vw;
    margin: 0 0.8vw;
}

.name-edit-delete {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 60%;
    height: 5.3vw;
}


.icon-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    height: 1.8vw;
    position: relative;
}

.pl-video-edit{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
}

.pl-video-edit input{
    font-family: 'Axiforma', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: white;
    background: #ae53fa;
    height: 45%;
    width: 95%;
    outline: none;
    border: 0;
    padding: 0 3%;
    margin: 2% 0 0 0;
}


.pl-video-edit .buttons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 56%;
}


.pl-video-edit .buttons button{
    font-size: 0.875rem;
    font-weight: 400;
    background: white;
    color: #9d17fb;
    height: 65%;
    width: 30%;
    border: none;
    border-radius: 5vw;
    margin: 0.3vw;
}

.icon-wrapper img{
    width: 1.09vw;
    height: 1.09vw;
    margin: 0 0.5vw;
}

.sah-icons-wrapper .icon-wrapper:not(:last-child) {
    margin-right: 0.9vw;
}

#ipn-from-all-playlists.input-playlist-name {
    /* position: absolute; */
    top: 15%;
    right: 25%;
    background-color: #fff;
    padding: .5%;
}

.input-playlist-name input {
    background-color: #b456fb;
    color: #fff;
    border: none;
    border-bottom: 0.1vw solid #fff;
    font-family: "Axiforma";
    font-size: 1vw;
    font-weight: 700;
    padding: 1vh 2vw;
    width: 100%;
}

.input-playlist-name .create-wrapper {
    display: flex;
    justify-content: flex-end;
}

.input-playlist-name .create {
    cursor: pointer;
    display: inline-block;
    background-color: #ba2ccd;
    font-family: "Axiforma", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    padding: 1vh;
}

.delContent{
  text-align: center;
  padding: 1vw;
  background: #dfdfdf;
}

.modal-close{
    display: flex;
    justify-content: flex-end;
}

.delContent h5{
  font-family: "Axiforma", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #9d17fb;
  margin: 1.5vw;
}

.delContent button{
  background: #9d17fb;
  font-family: "Axiforma", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  border: none;
  text-align: center;
  margin: auto;
  color: white;
  cursor: pointer;
  border-radius: 3vw;
  width: 5vw;
  height: 2.5vw;
  margin: 0.5vw;
}
