/* .addToplaylists {
    color: #B456FB;
    font-family: 'Axiforma';
    font-weight: 100;
    font-size: 1.5rem;
    font-style: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4.5vh;
    width:15vw;
    height: auto;
    max-height: 25vw;
    z-index: 1000;
    position: fixed;
    top: 3.5vw;
    right: 5vw;
    background: white;
    padding: 0.5vw;
    border-radius: 0.5vw;
} */

.addToplaylists {
  color: #B456FB;
  font-family: 'Axiforma';
  font-weight: 100;
  font-size: 1.5rem;
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4.5vh;
  width:15vw;
  height: auto;
  max-height: 25vw;
  z-index: 1000;
  position: absolute;
  top: 3.5vw;
  right: 5vw;
  background: white;
  padding: 0.5vw;
  border-radius: 0.5vw;
}


.addToplaylists .addToplaylist-list-wrapper {
  overflow-x:hidden;
  overflow-y: scroll;
  word-break: break-all;
  width: 100%;
  max-height: 100%;
}

.plList-element {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0.6vw;
}


.plList-element > h6 {
    font-weight: 400;
    padding-left: 15%;
    cursor: pointer;
    font-size: 1rem;
    font-family: "Axiforma", sans-serif;
    font-weight: 400;
    color: #7734b3;
}

.unchecked {
  width: 1vw;
  position: absolute;
  top: 0.82vw;
  left: 0.01vw;
}


.addToplaylist-list-wrapper::-webkit-scrollbar {
  width: 0.5vw;
}

.addToplaylist-list-wrapper::-webkit-scrollbar-track {
  border-radius: 0.5;
  box-shadow: inset 0 0 0.2vw rgba(0, 0, 0, 0.25);
}

.addToplaylist-list-wrapper::-webkit-scrollbar-thumb {
  border-radius: 0.5vw;
  background-color: #cc33ff;
}


.choosePL {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #cc33ff;
  width: 98%;
  align-items: center;
  padding: 0.2vw 0.7vw;
  margin-bottom: 0.3vw;
}

.choosePL h6{
  color: white;
  font-family: "Axiforma", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
}

.choosePL > div{
  cursor: pointer;
}

.notifications {
  width: 100%;
  height: 3vw;
  background-color: #897f8b;
  text-align: center;
  padding: 0.6vw;
  border: 0.1vw solid #7734b3;
  border-radius: 2vw;
}

.notifications h6{
  font-family: "Axiforma", sans-serif;
  font-size: 1rem;
  font-weight: lighter;
}
