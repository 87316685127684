.title-and-ShowAll{
  height: 5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.5vw 0;
  padding: 1.5vw 0;
}

.title-and-ShowAll h3{
  color: #cc33ff;
  font-family: 'Axiforma', sans-serif;
  font-size: 1.875rem;
  font-weight: 500;
}

.show-all-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.show-all {
    margin-right: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 1vh; */
    cursor: pointer;
}

.show-all #show-all-img {
    height: 1.3vw;
}

.arrows {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2vw;
}
