.first-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .first-content {
    width: 60vw;
  }
  
  .second-section{
    background: linear-gradient(#720da1,#1e002e);
    padding-top: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .second-content {
    width: 60vw;
  }
  