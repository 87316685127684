.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
    text-align: center;
}

.modal {
    position: fixed;
    margin-bottom: 1.46vw;
    margin: 8vw 0;
    background: #ffffff;
    border-radius: 0.5vw;
    width: 29.17vw;
    max-height: 80%;
    z-index: 100;
    overflow: hidden;
    text-align: right;
    left: calc(50% - 20rem);
    width: 40rem;
}

/* .modal .closeBtn{
  background: #4d194d;
  border: none;
  padding: .2rem;
  text-align: center;
  margin: auto;
  color: white;
  cursor: pointer;
  border-radius: 5px;
} */


/* @media (min-width: 768px) {
    .modal {
        left: calc(50% - 20rem);
        width: 40rem;
    }
} */
