.video-name-and-action {
    position: relative;
    color: #fff; 
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-items: center;
    padding: 1.5vw 0;
    width: 100%;
  }
  
  .withComments {
    width: 100%;
    height: 100%;
  }
  
  .video-name-and-action .video-name-members {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 70%;
  }
  
  .video-name-and-action .video-name-heading {
      font-family: 'Axiforma', sans-serif;
      font-size: 1.375rem;
      font-weight: 400;
  }
  
  .video-name-and-action .video-members {
      font-family: 'Axiforma', sans-serif;
      font-size: 1.25rem;
      font-weight: lighter;
      max-height: 1.6rem;
      text-overflow: ellipsis;
      overflow: hidden;
  }
  
  .video-name-and-action .actions-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 16%;
      align-items: center;
      position: relative;
  }
  
  .video-name-and-action .share {
      width: 45%!important;
      border-radius: 2vw;
  }
  
  .add-to-playlist {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 2vw;
      height: 2vw;
      padding: 0 1.3vw;
      width: 30%;
      overflow: hidden;
      margin: 0.2vw;
  }
  
  .add-to-playlist div{
      font-size: .9vw;
      margin: 0 0.5vw 0 0.3vw;
      padding-top: 0.26vw;
  }
  
  
  .add-to-playlist img {
      width: 2vw;
  }

  .analyze-wrapper {
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ring{
    position: relative;
    background: #000000;
    width: 5.1vw;
    height: 5.1vw;
    border: 0.3vw solid #26092e;
    border-radius: 50%;
    border-left: 0.3vw solid #cc33ff;
    box-shadow: 0 0 0 0.2vw #000000;
  }

  .analyze-wrapper .animate{
    animation: animate 4s linear infinite;
  }

  @keyframes animate {
    0%
    {
        transform: rotate(0deg);
    }
    100%
    {
        transform: rotate(360deg);
    }
  }

  .analyze{
    position: absolute;
    margin: auto;
    height: 4.6vw;
    width: 4.6vw;
    text-align: center;
    background: #cc33ff;
    color: #170231;
    border: 0.2vw solid #000000;
    padding: 1vw;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Axiforma", sans-serif;
    font-size: 0.8125rem;
    font-weight: bold;
  }


  .shareMenu {
    color: #B456FB;
    font-family: 'Axiforma';
    font-weight: 100;
    font-size: 1.5rem;
    font-style: normal;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4.5vh;
    width: 20vw;
    height: auto;
    max-height: 25vw;
    z-index: 1000;
    position: absolute;
    top: 3.5vw;
    right: 5vw;
    background: white;
    padding: 0.5vw;
    border-radius: 0.5vw;
  }
 
  