.content-table {
  min-width: 100%;
}

.recently-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "Axiforma", sans-serif;
}

.recently-header h4{
  font-size: 1.875rem;
  font-weight: 500;
}

.recently-header .admin-search{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  border: 0.03vw solid #c3f;
  border-radius: 2vw;
  background: transparent;
  height: 2.5vw;
}

.recently-header input{
  display: block;
  width: 85%;
  padding: 0.7rem;
  background: transparent;
  border: none;
  color: #c3f;
}

.recently-header input:focus{
  outline: none;
} 

.recently-header input::placeholder{
  color: #c3f;
  padding-left: 0.5vw;
}

.recently-header img{
  height: 1.875vw;
  width: 1.875vw;
  margin: 0.3vw;
}

.content-table .table-inner{
  width: 100%;
}

.content-table .table-inner .row {
  width: 100%;
  height: 3vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
  padding: 1rem;
  background-color: #210741;
  color: #fff;
  text-align: left;
  font-weight: 500;
  font-family: "Axiforma", sans-serif;
  font-size: 1.125rem;
  padding: 0.5vw;
  border-radius: 2vw;
}

.content-table .table-inner .row >div{
  padding: 0 1.5rem;
}

.row-inside{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 9vw;
}

.row-inside img{
  width: 1.615vw;
  height: 1.615vw;
}
